@import "../css/Variable.scss";

body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  // font-family: LKFuturaStd-Medium, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.42857;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 500px) {
  body {
    text-size-adjust: 85%;
  }
}

.footer {
  width: 100%;
  position: relative;
  bottom: 0;
  height: max-content;
  background-color: $footer-background;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: $border-light;
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(221, 221, 221);
  font-size: 14px;
  color: $footer-text;
  /* margin-top: 10px; */
  height: 60px;
}

.footer-down-social-menu {
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
  border-top: 1px solid rgb(221, 221, 221);
}

.footer-top {
  width: 100%;
  display: flex;
  // height: 100%;
  justify-content: space-between;
  background: #000042;
  color: white;
}

.footer-social-links-container {
  display: flex;
  // border-top: 1px solid rgb(221, 221, 221);
  font-size: 14px;
  color: white;
  height: 70px;
  padding: 1px 40px;
}

.footer-left-links {
  display: flex;
  width: 28%;
  justify-content: space-around;
}

.footer-right-links {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  justify-items: baseline;
}

.footer-left-title {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin: 20px;
}

.social-icons-footer {
  color: white;
  padding: 10px;
}

.footer-top-part {
  width: 30%;
  position: relative;
}

.footer-top-part-second {
  width: 50%;
  position: relative;
}

.footer-text-header {
  font-size: 25px;
  margin-left: 30px;
  padding-bottom: 0px;
  color: white;
  font-weight: 500;
}

.footer-text-link {
  font-size: 14px;
  line-height: 18px;
  margin-top: 16px;
  margin-bottom: 15px;
  margin-left: 30px;
  cursor: pointer;
  color: #fffdfd;
  font-family: auto;
}

.footer-app-store {
  padding: 30px;
  margin-top: 30px;
  display: ruby-text;
}

.footer-app-img-top {
  display: flex;
  margin-bottom: 15px;
}

.footer-app-parameter {
  font-size: 15px;
  width: max-content;
  margin: 0px;
}

.footer-app-img-disc {
  cursor: pointer;
}

.footer-app-p-graph {
  display: ruby;
}

.footer-top-side-container {
  background: #000042;
}

.footer-right-center {
  display: flex;
  margin: 10px;
}

.footer-right-social-link-title {
  display: flex;
  font-size: 23px;
  margin: 15px;
}

// .footer-text-link:hover {
//   color: rgb(192, 117, 19);
//   text-shadow: 1px 1px 1px rgb(255, 255, 255);
// }
.Footer-middle-top {
  padding: 20px 40px;
  background: #000042;
}

.footer-top-tag {
  color: white;
  font-size: 30px;
  font-weight: 500;
  // font-family: LKSans-Regular;
}

.footer-app-tittle-parameter {
  color: white;
  font-size: 14px;
  line-height: 20px;
}

.footer-text-header {
  margin-right: 30px;
}

.media-links {
  height: 70px;
}

// social media 2

.socialcard {
  width: fit-content;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  gap: 20px;
  // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}

/* for all social containers*/
.socialContainer {
  width: 52px;
  height: 52px;
  background-color: rgb(44, 44, 44);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.3s;
  border-radius: 50px;
}
/* instagram*/
.containerOne:hover {
  background-color: #d62976;
  transition-duration: 0.3s;
}
/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: 0.3s;
}
/* linkdin*/
.containerThree:hover {
  background-color: #0072b1;
  transition-duration: 0.3s;
}
/* Whatsapp*/
.containerFour:hover {
  background-color: #35c827;
  transition-duration: 0.3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: 0.3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.socials-container {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.social {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgb(194, 194, 194);
  background-color: #333333;
}
.twitter:hover {
  background: linear-gradient(45deg, #66757f, #00acee, #36daff, #dbedff);
}
.facebook:hover {
  background: linear-gradient(45deg, #134ac0, #316ff6, #78a3ff);
}
.google-plus:hover {
  background: linear-gradient(45deg, #872419, #db4a39, #ff7061);
}
.instagram:hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.social svg {
  fill: white;
  height: 20px;
}

.dark-theme {
  .footer {
    width: 100%;
    position: relative;
    bottom: 0;
    height: 48vh;
    background-color: #000000da;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: $border-light;
  }

  .footer-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(221, 221, 221);
    font-size: 14px;
    color: white;
    /* margin-top: 10px; */
    height: 60px;
  }

  .footer-top {
    margin-top: 10px;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .footer-top-part {
    width: 25%;
    position: relative;
  }

  .footer-text-header {
    font-size: 16px;
    margin-left: 40px;
    border-bottom: 1px solid rgb(219, 219, 219);
    padding-bottom: 10px;
    color: white;
  }

  .footer-text-link {
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 40px;
    cursor: pointer;
    color: white;
  }

  .footer-text-link:hover {
    color: rgb(192, 117, 19);
    text-shadow: 1px 1px 1px rgb(255, 255, 255);
  }

  .footer-text-header {
    margin-right: 30px;
  }

  .media-links {
    height: 100px;
  }

  .socialcard {
    background-color: black;
  }
}
